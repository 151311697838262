import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../utils/mediaQuery";
import { patterns } from "../assets/data/patterns";

const PatternCard = ({ image, pdf, title, category, workshop }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="300"
          image={image}
          alt="item image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {workshop}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <a href={pdf} target="_blank" download>
            See pattern
          </a>
        </Button>
      </CardActions>
    </Card>
  );
};

export const Patterns = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const picName = "baby-dress-1";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const param = process.env.REACT_APP_STORAGE_SAS;

  return (
    <>
      <Stack>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            width: "100%",
            padding: "2rem",
          }}
        >
          <Typography
            variant={width > 768 ? "h2" : "h3"}
            color={theme.palette.primary.main}
          >
            Our patterns
          </Typography>

          <Button component={RouterLink} to={"/projects"} variant="contained">
            Back To Projects
          </Button>
        </Box>
        {/* <Divider variant="middle" /> */}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            width: "100%",
            padding: "2rem",
          }}
        >
          {patterns.map((item) => (
            <PatternCard
              image={`${baseUrl}${item.picName}.jpg?${param}`}
              pdf={`${baseUrl}${item.fileName}.pdf?${param}`}
              title={item.title}
              category={item.category}
              workshop={
                !!item?.workshop ? item.workshopTitle : "Level: easy/beginner"
              }
            />
          ))}
        </Box>
      </Stack>
    </>
  );
};
