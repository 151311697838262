export const patterns = [
  {
    title: "Simple baby dress",
    fileName: "baby-dress-1-knitting-pattern",
    picName: "baby-dress-1-pic",
    category: "knitting",
  },
  {
    title: "Crochet coasters",
    fileName: "crochet-coasters",
    picName: "crochet-workshop-1",
    category: "crochet",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #1",
  },
  {
    title: "Crochet twirls",
    fileName: "crochet-twirls",
    picName: "crochet-workshop-2",
    category: "crochet",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #2",
  },
  {
    title: "Xmas decorations",
    fileName: "crochet-xmas-decorations",
    picName: "crochet-workshop-3",
    category: "crochet",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #3",
  },
  {
    title: "Crochet pride keychains",
    fileName: "crochet-pride-keychains",
    picName: "crochet-workshop-4",
    category: "crochet",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #4",
  },
  {
    title: "Crochet easy flowers",
    fileName: "crochet-easy-flowers",
    picName: "crochet-workshop-5",
    category: "crochet",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #4",
  },
];
